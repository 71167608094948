import React from 'react'
import './Services.css';
import HeartEmoji from '../../img/heartemoji.png';
import Glasses from '../../img/glasses.png';
import Humble from '../../img/humble.png';
import Card from '../Card/Card';
import {themeContext} from '../../Context';
import {useContext} from 'react';
import {motion} from 'framer-motion';
const Services = () => {

  const transition = {duration: 2, type: 'spring'}
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className='services' id='Services'>
      
      { /* Left Side */ }
      <div className="awesome">
        <span style={{color: darkMode ? 'white' : ''}}>My Awesome</span>
        <span>Services</span>
        <span>Lorem ipsum is simpley dummy text of printing of printing Lorem
            <br/>
            ipsum is simpley dummy text of printing</span>
        <button className="button s-button">Download CV</button>
        <div className='blur s-blur' style={{background: "#ABF1FF94" }}></div>    
      </div>

      { /* Right Side */ }
      <div className="cards">

        { /* First Card */ }
          <motion.div 
          initial={{left: '25rem'}}
          whileInView={{left: '14rem'}}
          transition={transition}
          style={{left: "14rem"}}>
            <Card
            emoji = {HeartEmoji}
            heading = 'Design'
            detail = 'Figma, Sketch, Photoshop, Adobe Illustrator, Adobe xd' />
          </motion.div>
          
          { /* Second Card */ }
          <motion.div 
          initial={{top:'12rem',left: '-15rem'}}
          whileInView={{left: '-4rem'}}
          transition={transition}
          style={{top: "12rem",left: "-4rem"}}>
            <Card
            emoji = {Glasses}
            heading = 'Developer'
            detail = 'Html, CSS, JavaScript, React, Php, Laravel, Python' />
          </motion.div>

          { /* Second Card */ }
          <motion.div 
          initial={{top:'19rem',left: '35rem'}}
          whileInView={{left: '12rem'}}
          transition={transition}
          style={{top: "19rem",left: "12rem"}}>
            <Card
            emoji = {Humble}
            heading = 'UI/UX'
            detail = 'Lorem ipsum dummy text are usually use in section where we need some random text' />
          </motion.div>
          <div className="blur s-blur2" style={{background:"var(--purple)" }}></div>
      </div>
    </div>
  )
}

export default Services
